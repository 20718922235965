import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import qualys from "../../../static/images/partners/members/qualys.svg"
import robota from "../../../static/images/partners/members/robota.svg"
import aws from "../../../static/images/partners/members/aws.svg"
import microsoft from "../../../static/images/partners/members/microsoft-partner.svg"
import google from "../../../static/images/partners/members/google.svg"
import devndel from "../../../static/images/partners/members/devndel.svg"
import secture from "../../../static/images/partners/members/secture.svg"
import flat from "../../../static/images/partners/members/flat101.svg"
import yoseo from "../../../static/images/partners/members/yoSEO.svg"
import wazuh from "../../../static/images/partners/members/wazuh.svg"
import mymoid from "../../../static/images/partners/members/mymoid.svg"

const PartnerMiembros = () => {
  const data = useStaticQuery(graphql`
    query ImagesMiembros {
      image: allFile(
        filter: { relativeDirectory: { eq: "partners" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  function AWS(){window.open("https://aws.amazon.com/es/")}
  function Microsoft(){window.open("https://partner.microsoft.com/es-ES/")}
  function Google(){window.open("https://www.google.com/partners/about/")}
  function Qualys(){window.open("https://www.qualys.com/")}
  function Robota(){window.open("https://www.robota.net/")}
  function DevnDel(){window.open("https://www.devanddel.com/")}
  function Secture(){window.open("https://secture.com/")}
  function Flat101(){window.open("https://www.flat101.es/")}
  function yoSEO(){window.open("https://www.yoseomarketing.com/")}
  function Wazuh(){window.open("https://wazuh.com/")}
  function MYMOID(){window.open("https://experts.mymoid.com/")}

  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Partners y Alianzas | Qualoom</title>
        <meta name="title" content="Partners y Alianzas | Qualoom"/>
        <meta name="description" content="Desarrollamos y mantenemos relaciones estratégicas con líderes y empresas de tecnología con una fuerte relación de trabajo y un enfoque profesional."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/partner-program/miembros/"/>
        <link rel="canonical" href="https://www.qualoom.es/partner-program/miembros/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>

        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/partner-program/miembros/"/>
        <meta property="og:title" content="Partners y Alianzas | Qualoom"/>
        <meta property="og:description" content="Desarrollamos y mantenemos relaciones estratégicas con líderes y empresas de tecnología con una fuerte relación de trabajo y un enfoque profesional."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3MRtNIyAwtyGPXOaftMby9/5717580e7dcee1881c0f956118deb692/tw_cards_partners.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>

        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/partner-program/miembros/"/>
        <meta property="twitter:title" content="Partners y Alianzas | Qualoom"/>
        <meta property="twitter:description" content="Desarrollamos y mantenemos relaciones estratégicas con líderes y empresas de tecnología con una fuerte relación de trabajo y un enfoque profesional."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3MRtNIyAwtyGPXOaftMby9/5717580e7dcee1881c0f956118deb692/tw_cards_partners.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/partner-program/miembros/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Partners y Alianzas", "item": {"@id": "https://www.qualoom.es/partner-program/miembros/", "name": "Partners y Alianzas"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-partner">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Conoce a todos los miembros de nuestro Partner Program</h1>
              <p>Presentamos a todas las empresas y organizaciones que forman parte de esta comunidad, porque cuando un buen servicio genera confianza y crea alianzas de valor tiene una garantía indiscutible.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="partner-main">
        <div className="partner-main-content">
          <h2>Cloud & Infraestructura</h2>
          <div className="partner-member">
            <div className="member-item" onClick={AWS} onKeyDown aria-hidden><img src={aws} alt="Amazon Web Services"></img></div>
            <div className="member-item" onClick={Microsoft} onKeyDown aria-hidden><img src={microsoft} alt="Microsoft Partner"></img></div>
            <div className="member-item" onClick={Google} onKeyDown aria-hidden><img src={google} alt="Google"></img></div>
          </div>
        </div>
      </div>
      <div className="partner-main gray">
        <div className="partner-main-content">
            <h2>Métodos de pago seguros</h2>
            <div className="partner-member"><div className="member-item-bg-gray" onClick={MYMOID} onKeyDown aria-hidden><img src={mymoid} alt="MYMOID"></img><p>En Qualoom contamos con <b>MYMOID como partner especializado en medios de pago</b>, una compañía que se desarrollo hace 12 años con el objetivo de ofrecer una sólida estrategia de soluciones pagos, específicamente en la seguridad del proceso de pago, la actualización trimestral, consultoría anual y el monitoreo continuo, contando con un método de seguridad efectiva en la verificación PCI DSS, directiva Europea PSD2, auditoria continua y biometría. </p></div></div>
          </div>
      </div>
      <div className="partner-main">
        <div className="partner-main-content">
          <h2>Seguridad informática</h2>
          <div className="partner-member"><div className="member-item-bg-white" onClick={Qualys} onKeyDown aria-hidden><img src={qualys} alt="Qualys"></img><p>Qualoom Expertise Technology ha identificado el valor de la estratégia y <b>servicios de seguridad de la información y cumplimiento</b> que Qualys como lider en este tipo de soluciones lleva desarrollando más de 10 años, motivo por el cual han formalizado su relación como partners en la implantación y mantenimiento de soluciones de seguridad, soluciones de identificación de vulnerabilidades, cumplimiento, PCI-DSS, Web y Gobierno. </p></div></div>
          <div className="partner-member"><div className="member-item-bg-white" onClick={Robota} onKeyDown aria-hidden><img src={robota} alt="Robota"></img><p>En Qualoom Expertise Technology hemos identificado <b>la especialización exclusiva en servicios y soluciones de seguridad informática</b> que Robota lleva desarrollando desde hace 20 años, motivo por el cual se ha formalizado una relación como partners ampliando nuestro dossier de servicios en el ámbito de la seguridad de la información.</p></div></div>
          <div className="partner-member"><div className="member-item-bg-white" onClick={Wazuh} onKeyDown aria-hidden><img src={wazuh} alt="Wazuh"></img><p><b>Wazuh</b> es una de las compañías revelación en el ecosistema de ciberseguridad que ofrece soluciones open source para la monitorización y detección de amenazas, la monitorización de la integridad, la respuesta a incidentes y el cumplimiento en el ámbito de la seguridad de la información.</p></div></div>
        </div>
      </div>
      <div className="partner-main gray">
        <div className="partner-main-content">
          <h2>Desarrollo y DevSecOps</h2>
          <div className="partner-member"><div className="member-item-bg-gray" onClick={DevnDel} onKeyDown aria-hidden><img src={devndel} alt="Dev&Del"></img><p><b>Dev&Del y Qualoom</b> cierran una alianza estratégica por la que ambas compañías ofrecen su dossier de servicios de manera conjunta completando una propuesta global y de calidad, cuyo fin, es proveer a todos sus clientes de una oferta de <b>servicios de infraestructura, Cloud, consultoría, desarrollo de software, programación segura, DevOps, DevSecOps y equipos de alto rendimiento.</b></p></div></div>
          <div className="partner-member"><div className="member-item-bg-gray" onClick={Secture} onKeyDown aria-hidden><img src={secture} alt="Secture"></img><p><b>Secture y Qualoom</b> certifican sus sinergias y ámbitos de negocio por el que acuerdan ir de la mano en oportunidades estratégicas centrando sus <b>servicios en el desarrollo de aplicaciones web con las últimas tecnologías</b> y al mismo tiempo el <b>servicio de despliegue, mantenimiento, soporte, monitorización y alarmado de infraestructuras críticas y altamente escalables</b></p></div></div>
        </div>
      </div>
      <div className="partner-main">
        <div className="partner-main-content">
          <h2>Marketing Digital, CRO, Posicionamiento SEO, Campañas PPC - SEM, Redes Sociales</h2>
          <div className="partner-member"><div className="member-item-bg-white" onClick={Flat101} onKeyDown aria-hidden><img src={flat} alt="Flat 101"></img><p><b>Qualoom</b> formalizó con <b>Flat101</b> un acuerdo preferente de partnership como referente en optimización y conversión a nivel nacional e internacional, <b>especializados en soluciones digitales, comercio electrónico, tráfico, conversión web y UX, así como el desarrollo web especializado</b>. Una garantía nacional de calidad software y gestión de proyectos.</p></div></div>
          <div className="partner-member"><div className="member-item-bg-white" onClick={yoSEO} onKeyDown aria-hidden><img src={yoseo} alt="yoSEO"></img><p>La agencia <b>yoSEO</b> especializada en <b>SEO proactivo</b> (consultoría SEO, auditoría SEO, SEO ecommerce), <b>Diseño Web y UX</b>, <b>PPC (Publicidad)</b> (Google Ads, Fabebook Ads, Instagram Ads y YouTube Ads) y <b>estrategias de creación de contenidos</b> (Marketing de contenidos, Email Marketing e Inbound Marketing),cerró con <b>Qualoom</b> un importante acuerdo en el que se redefinen las estrategias digitales con fin de potenciar y mejorar el rendimiento de los portales web más exigentes. Gracias a este acuerdo se han cerrado cientos de proyectos de optimización con claros resultados.</p></div></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PartnerMiembros
